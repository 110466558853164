$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; @keyframes flashing {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@mixin progress-dot {
  content: "\25CF";
  font-family: Söhne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif;
  line-height: normal;
  margin-left: 0.25rem;
  vertical-align: baseline;
  animation: flashing 1.5s 3s infinite;
  display: inline-block;
  font-size: 1rem;
  color: var(--tertiary-medium);
}

.streamable-content.streaming .cooked p:last-child::after {
  @include progress-dot;
}

article.streaming .cooked {
  .progress-dot::after {
    @include progress-dot;
  }

  > .progress-dot:only-child::after {
    // if the progress dot is the only content
    // we are likely waiting longer for a response
    // so it can start animating instantly
    animation: flashing 1.5s infinite;
  }
}

@keyframes ai-indicator-wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-0.2em);
  }
}

.ai-indicator-wave {
  flex: 0 0 auto;
  display: inline-flex;

  &__dot {
    display: inline-block;
    @media (prefers-reduced-motion: no-preference) {
      animation: ai-indicator-wave 1.8s linear infinite;
    }
    &:nth-child(2) {
      animation-delay: -1.6s;
    }
    &:nth-child(3) {
      animation-delay: -1.4s;
    }
  }
}
.composer-ai-helper-modal {
  .text-preview,
  .inline-diff {
    ins {
      background-color: var(--success-low);
      text-decoration: underline;
    }
    del {
      background-color: var(--danger-low);
      text-decoration: line-through;
    }

    .preview-area {
      height: 200px;
    }
  }
  @keyframes fadeOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  &__loading {
    animation: fadeOpacity 1.5s infinite alternate;
  }

  &__old-value {
    background-color: var(--danger-low);
    color: var(--danger);
    margin-bottom: 1rem;
  }

  &__new-value {
    background-color: var(--success-low);
    color: var(--success);
  }

  .d-modal__footer {
    .regenerate {
      margin-left: auto;
    }
  }
}

.topic-above-suggested-outlet.related-topics {
  margin: 4.5em 0 1em;
}

.ai-composer-helper-menu {
  padding: 0.25rem;
  max-width: 25rem;
  list-style: none;

  ul {
    margin: 0;
    list-style: none;
  }
}

.ai-custom-prompt {
  display: flex;
  gap: 0.25rem;
  padding: 0.75em 1rem;

  &__input[type="text"] {
    border-color: var(--primary-400);
    margin-bottom: 0;

    &::placeholder {
      color: var(--primary-medium);
    }
  }
}

.ai-helper-loading {
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  .dot-falling {
    margin-inline: 1rem;
    margin-left: 1.5rem;
  }
}

.d-editor-input.loading {
  animation: loading-text 1.5s infinite linear;
}

@keyframes loading-text {
  0% {
    color: var(--primary);
  }
  50% {
    color: var(--tertiary);
  }
  100% {
    color: var(--primary);
  }
}

.ai-helper-highlighted-selection {
  background-color: var(--highlight-low-or-medium);
}

// AI Typing indicator (taken from: https://github.com/nzbin/three-dots)
.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--tertiary);
  color: var(--tertiary);
  box-shadow: 9999px 0 0 0 var(--tertiary);
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--tertiary);
  color: var(--tertiary);
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--tertiary);
  color: var(--tertiary);
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 var(--tertiary);
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 var(--tertiary);
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 var(--tertiary);
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

// Suggest Titles Related

.showing-ai-suggestions {
  .title-input {
    // border on focus should be on top of suggestion button
    input:focus {
      z-index: 1;
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .category-chooser,
  .mini-tag-chooser {
    .select-kit-header {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  #edit-title {
    padding-right: 2em;
  }

  .category-chooser {
    .select-kit-header-wrapper {
      padding-right: 1.5em;
    }
  }

  .mini-tag-chooser {
    .multi-select-header {
      padding-right: 2em;
    }
  }

  .select-kit.is-expanded {
    // need to raise the z-index so the sibling input buttons don't cover the dropdown
    z-index: z("dropdown") + 1;
    + button {
      z-index: z("dropdown") + 1;
    }
  }
}

.suggestion-button {
  .d-icon-spinner {
    animation: spin 1s linear infinite;
  }
}

.edit-title__wrapper,
.edit-category__wrapper,
.edit-tags__wrapper {
  position: relative;
}

.suggest-titles-button,
.suggest-tags-button,
.suggest-category-button {
  position: absolute;
  right: 0;
  top: 1px; // container border width
  z-index: z("dropdown");

  #reply-control & {
    z-index: z("composer", "dropdown") + 1;
  }
}

#reply-control {
  .composer-popup {
    // need to raise the z-index here
    // because we need another layer to put the AI icon above dropdowns
    // while also keeping them below the composer tips
    z-index: z("composer", "dropdown") + 2;
  }
}

.ai-category-suggester-content,
.ai-tag-suggester-content,
.ai-title-suggester-content {
  z-index: z("composer", "dropdown");
}

.ai-suggestions-menu .btn {
  text-align: left;
}

.mobile-view {
  .ai-category-suggester-content,
  .ai-tag-suggester-content,
  .ai-title-suggester-content {
    z-index: z("modal", "dropdown");
  }
}

.ai-category-suggester-content {
  .category-row {
    padding: 0.25em 0.5em;
    color: var(--primary-high);

    &:hover {
      background: var(--d-hover);
    }
  }

  .topic-count {
    font-size: var(--font-down-2);
  }
}

.ai-tag-suggester-content {
  .tag-row {
    .discourse-tag-count {
      margin-left: 5px;
    }

    .d-button-label {
      display: none;
    }
  }
}

.edit-topic-title {
  .suggestion-button {
    margin: 0;
    padding: 0.465rem;
  }
}

#topic-title .edit-topic-title.showing-ai-suggestions {
  #edit-title {
    flex: 1 1 90%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .suggest-titles-button {
    padding: 0.5rem;
  }
}

// Prevent suggestion button from wrapping
#reply-control {
  .with-category .showing-ai-suggestions .category-input {
    flex-wrap: nowrap;
    max-width: calc(50% - 0.2em);
    .category-chooser {
      min-width: 0;
      flex: 1 1 auto;
    }
  }

  .with-category:not(.with-tags) {
    // when tagging is disabled
    .showing-ai-suggestions .category-input {
      max-width: 40%;
    }
  }

  .with-tags {
    .showing-ai-suggestions .tags-input {
      display: flex;
      max-width: calc(50% - 0.2em);
      .mini-tag-chooser {
        min-width: 0;
      }
    }
  }

  .showing-ai-suggestions {
    #reply-title {
      padding-right: 2em;
    }
  }
}

.suggest-tags-button + .ai-suggestions-menu {
  top: 4.25rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.ai-post-helper {
  &__suggestion {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: var(--font-0);
      border-bottom: 1px solid var(--primary-low);
      padding-bottom: 0.5em;
    }

    p {
      margin: 0;
    }

    &__copy {
      .d-icon-check {
        color: var(--success);
      }
    }

    &__text {
      padding: 0.5rem;
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .btn {
        flex-grow: 1;
        padding-inline: 0;
      }
    }
  }

  &__fast-edit {
    .fast-edit-container {
      padding: 0.75em 1rem;
    }
  }
}

.choose-topic-modal .split-new-topic-form {
  .control-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.25em;
    margin-bottom: 1rem;

    label {
      flex: 100%;
    }

    input,
    .combo-box,
    .multi-select {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .ai-split-topic-suggestion-button {
    .d-icon-spinner {
      animation: spin 1s linear infinite;
    }
  }
}

.ai-split-topic-suggestion__results {
  list-style: none;
  margin-left: 0;
  margin: 0;

  .btn {
    display: block;
    width: 100%;
    text-align: left;
    background: none;

    &:hover,
    &:focus {
      background: var(--d-hover);
      color: var(--primary);
    }
  }

  li:not(:last-child) {
    border-bottom: 1px solid var(--primary-low);
  }

  .ai-split-topic-suggestion__category-result {
    font-size: var(--font-0);
    padding: 0.5em 1rem;

    &:hover,
    &:focus {
      background: var(--d-hover);
      cursor: pointer;
    }
  }

  .topic-count {
    font-size: var(--font-down-2);
    color: var(--primary-high);
  }
}

.fk-d-menu[data-identifier="ai-split-topic-suggestion-menu"] {
  z-index: z("modal", "dropdown");
}

.ai-split-topic-loading-placeholder {
  .d-icon-spinner {
    animation: spin 1s linear infinite;
  }

  + .ai-split-topic-suggestion-button {
    display: none;
  }
}

.thumbnail-suggestions-modal {
  .ai-thumbnail-suggestions {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    gap: 0.5em;

    &__item {
      flex: 35%;
      position: relative;
    }

    img {
      width: 100%;
      height: auto;
    }

    .btn {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
    }
  }
}

// AI Image Caption Feature:
.image-wrapper {
  .button-wrapper {
    .generate-caption {
      background: var(--tertiary-low);
      color: var(--tertiary);
      box-shadow: var(--shadow-dropdown);
      position: absolute;
      white-space: nowrap;
      top: -2rem;
      left: 0.35rem;
      padding: 0.33em 0.75em;
      transition: all 0.25s ease;
      .discourse-no-touch & {
        display: none;
      }

      .d-icon {
        margin-right: 0.25rem;
      }

      &:active {
        box-shadow: none;
      }

      &:hover,
      &:focus {
        background: var(--tertiary-400);
        color: var(--tertiary-hover);
        cursor: pointer;
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }
  .discourse-no-touch & {
    &:hover {
      .button-wrapper .generate-caption {
        display: block;
      }
    }
  }
}

.ai-caption-popup {
  --ai-caption-popup-min-width: 20rem;
  width: auto;
  right: unset;
  padding: 1em;
  top: unset;
  bottom: 0;

  .loading-container {
    min-width: var(--ai-caption-popup-min-width);
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    max-width: 40dvw;
    max-height: calc(100dvh - var(--header-offset) - 10em);
    min-height: 3em;
    height: 7em;
    min-width: var(--ai-caption-popup-min-width);
    @include breakpoint(tablet) {
      width: 100%;
      max-width: unset;
      min-width: unset;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .credits {
      font-size: var(--font-down-1);
      margin-left: auto;
      color: var(--tertiary);
      .desktop-view & {
        // a little extra space for extra narrow desktop view
        @media screen and (max-width: 675px) {
          span {
            display: none;
          }
        }
      }
    }
  }

  .spinner {
    border-color: var(--tertiary-600);
    border-right-color: var(--tertiary);
  }
}

.ai-image-caption-prompt-dialog {
  .dialog-content {
    max-width: 555px;
  }
}

.auto-image-caption-loader {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-high);
}

// AI Helper Options List
.ai-helper-options {
  margin: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;

    .shortcut {
      border: none;
      background: none;
      font-size: var(--font-down-1);
      color: var(--primary-low-mid);
      margin-left: auto;
    }
  }

  &__button {
    justify-content: left;
    text-align: left;
    background: none;
    width: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0.5em 1rem;

    &:focus,
    &:hover {
      color: var(--primary);
      background: var(--d-hover);

      .discourse-no-touch & {
        color: var(--primary);
        background: var(--d-hover);
      }

      .d-icon {
        color: var(--primary-high);

        .discourse-no-touch & {
          color: var(--primary-high);
        }
      }
    }
  }
}

.fk-d-menu[data-identifier="ai-composer-helper-menu"],
.fk-d-menu[data-identifier="ai-title-suggester"] {
  z-index: z("modal", "dialog");

  .fullscreen-composer & {
    z-index: z("header") + 1;
  }

  .mobile-view & {
    z-index: z("mobile-composer");
  }
}

.fk-d-toasts:has(.ai-proofread-error-toast) {
  top: unset;
  bottom: calc(var(--composer-height) - 5%);
  right: unset;
  left: 0;
}

@media screen and (min-width: $reply-area-max-width) {
  .has-sidebar-page {
    .fk-d-toasts:has(.ai-proofread-error-toast) {
      transform: translateX(
        calc(
          (100vw - var(--d-max-width) - var(--d-sidebar-width) / 0.5) / 2 + 17em +
            1rem
        )
      );
    }
  }
}
.topic-map {
  // Hide the Top Replies label if summarization is enabled
  &:has(.topic-map__additional-contents .ai-summarization-button) {
    .top-replies {
      .d-icon {
        margin: 0;
        height: 1.2em;
      }
      .d-button-label {
        display: none;
      }
    }
  }

  // Hide the Summarize label when there are many stats
  &:has(.--many-stats):has(.top-replies) .topic-map__additional-contents {
    button {
      .d-icon {
        margin: 0;
        height: 1.2em;
      }
      .d-button-label {
        display: none;
      }
    }
  }
}

.ai-summary-modal {
  .ai-summary {
    &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
    }
    &__list-item {
      background: var(--primary-300);
      border-radius: var(--d-border-radius);
      margin-right: 8px;
      margin-bottom: 8px;
      height: 1em;
      opacity: 0;
      display: block;
      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 12%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 14%;
      }

      &:nth-child(5) {
        width: 18%;
      }

      &:nth-child(6) {
        width: 14%;
      }

      &:nth-child(7) {
        width: 22%;
      }

      &:nth-child(8) {
        width: 05%;
      }

      &:nth-child(9) {
        width: 25%;
      }

      &:nth-child(10) {
        width: 14%;
      }

      &:nth-child(11) {
        width: 18%;
      }

      &:nth-child(12) {
        width: 12%;
      }

      &:nth-child(13) {
        width: 22%;
      }

      &:nth-child(14) {
        width: 18%;
      }

      &:nth-child(15) {
        width: 13%;
      }

      &:nth-child(16) {
        width: 22%;
      }

      &:nth-child(17) {
        width: 19%;
      }

      &:nth-child(18) {
        width: 13%;
      }

      &:nth-child(19) {
        width: 22%;
      }

      &:nth-child(20) {
        width: 25%;
      }
      &.is-shown {
        opacity: 1;
      }
      &.show {
        animation: appear 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s forwards;
        @media (prefers-reduced-motion) {
          animation-duration: 0s;
        }
      }
      @media (prefers-reduced-motion: no-preference) {
        &.blink {
          animation: blink 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        }
      }
    }
    &__generating-text {
      display: inline-block;
      margin-left: 3px;
    }
  }

  .placeholder-summary {
    padding-top: 0.5em;
  }

  .placeholder-summary-text {
    display: inline-block;
    height: 1em;
    margin-top: 0.6em;
    width: 100%;
  }

  .generated-summary p {
    margin: 0;
  }

  .outdated-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    button {
      margin-top: 0.5em;
    }
    p {
      color: var(--primary-medium);
    }
  }

  .d-modal__footer {
    display: grid;
    gap: 0;
    grid-template-areas: "summarized regenerate" " outdated regenerate";
    grid-template-columns: 1fr auto;
    @include breakpoint(mobile-large) {
      gap: 0.25em 0.5em;
      grid-template-areas: "summarized summarized" "regenerate outdated";
    }

    p {
      margin: 0;
    }

    .fk-d-tooltip__trigger {
      vertical-align: text-top;
    }

    .summary-outdated {
      color: var(--primary-high);
      font-size: var(--font-down-1);
      line-height: var(--line-height-medium);
    }

    .summarized-on {
      grid-area: summarized;
    }

    button {
      grid-area: regenerate;
      justify-self: start;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.topic-list-layout-content {
  .btn.--with-description {
    display: grid;
    grid-template-areas: "icon title" "icon description";
    grid-template-columns: auto 1fr;
    text-align: left;
    .btn__description {
      grid-area: description;
      width: 100%;
      font-size: var(--font-down-1);
      color: var(--primary-high);
    }
  }

  .btn:focus {
    background: transparent;
  }

  .btn:focus-visible {
    outline: 2px solid var(--tertiary);
    background: transparent;
    outline-offset: -2px;
  }

  .btn.--active {
    background: var(--d-selected);
  }
}

.topic-list-layout-table-ai {
  .topic-list-item {
    .link-bottom-line {
      font-size: var(--font-down-1);
      margin-top: 0.25em;
      line-height: var(--line-height-medium);
    }
    .excerpt {
      width: 100%;
      line-height: var(--line-height-large);
      margin-top: 0.15em;
      margin-bottom: 0.15em;
      &__contents {
        max-width: 70ch;
        overflow-wrap: break-word;
      }
    }
    &:not(.visited) {
      .excerpt {
        color: var(--primary-high);
      }
    }
  }
  .topic-excerpt {
    display: none;
  }

  .mobile-view & {
    .topic-list-item .excerpt {
      margin-top: -0.25em;
      margin-bottom: 0.25em;
    }

    .topic-item-stats .num.activity {
      align-self: end;
      margin-bottom: -0.15em; // vertical alignment
    }
  }
}
nav.post-controls .actions button.cancel-streaming {
  display: none;
}

.ai-bot-chat {
  #reply-control {
    .user-selector,
    .title-and-category,
    #private-message-users {
      display: none;
    }
  }
  .persona-llm-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-kit.single-select.dropdown-select-box ul.select-kit-collection {
      max-height: 200px;
    }
    margin-bottom: 1em;
  }
}

.ai-bot-pm {
  .gpt-persona {
    margin-bottom: 5px;
  }
  #reply-control .composer-fields {
    .mini-tag-chooser,
    .add-warning {
      display: none;
    }
  }
}

.ai-bot-chat-warning {
  color: var(--tertiary);
  background-color: var(--tertiary-low);
  border-top: 1px solid var(--tertiary-medium);
  opacity: 0.75;
  .d-icon {
    color: var(--tertiary);
  }
  margin: 0;
  padding: 4px 10px;
  width: calc(100% - 20px);
}

article.streaming nav.post-controls .actions button.cancel-streaming {
  display: inline-block;
}

.ai-bot-available-bot-options {
  padding: 0.5em;

  .ai-bot-available-bot-content {
    color: var(--primary-high);
    display: flex;
    width: 100%;
    min-width: 320px;
    padding: 0.5em;
    .d-button-label {
      flex: 1;
      text-align: left;
    }
    &:hover {
      background: var(--primary-low);
    }
  }
}

.topic-body .persona-flair {
  order: 2;
  font-size: var(--font-down-1);
  padding-top: 3px;
}

details.ai-quote {
  > summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:first-child {
      margin-right: auto;
    }
    span:nth-child(2) {
      font-size: var(--font-down-2);
      background: var(--primary-medium);
      padding: 2px 6px 0;
      color: var(--secondary);
    }
  }
}

.ai-share-modal {
  .d-modal__footer {
    position: relative;
    padding: 10px 20px 25px;
    .btn-primary {
      margin-left: auto;
    }
  }
  &__just-copied {
    position: absolute;
    font-size: var(--font-down-1);
    right: 20px;
    bottom: 5px;
    color: var(--success);
  }
}

span.onebox-ai-llm-title {
  font-weight: bold;
}

.d-modal.ai-debug-modal {
  --modal-max-width: 99%;
  ul {
    padding-left: 1em;
  }

  li {
    margin-bottom: 0.2em;
  }

  li > ul {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
}

.ai-debug-modal__tokens span {
  display: block;
}

.d-modal ul.ai-debug-modal__nav {
  margin: 0 0 1em;
  padding: 0;
  border-bottom: none;
}
.admin-contents .ai-persona-list-editor {
  margin-top: 0;
}

.ai-persona-list-editor {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1em 0;

    h3 {
      margin: 0;
    }
  }

  &__current {
    padding-left: 20px;
  }

  li.disabled {
    opacity: 0.5;
  }
}

.ai-persona-tool-option-editor {
  &__instructions {
    color: var(--primary-medium);
    font-size: var(--font-down-1);
    line-height: var(--line-height-large);
  }
}

.ai-personas__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.ai-persona-editor {
  padding-left: 0.5em;

  .fk-d-tooltip__icon {
    padding-left: 0.25em;
    color: var(--primary-medium);
  }
  label {
    display: block;
  }
  &__tool-options {
    padding: 5px 10px 5px;
    border: 1px solid var(--primary-low-mid);
    width: 480px;
  }
  &__tool-options-name {
    margin-bottom: 10px;
    font-size: var(--font-down-1);
  }
  &__description {
    width: 500px;
  }
  &__system_prompt {
    width: 500px;
    height: 400px;
  }

  &__tool-details,
  &__vision_enabled,
  &__allow_chat_direct_messages,
  &__allow_chat_channel_mentions,
  &__allow_topic_mentions,
  &__allow_personal_messages,
  &__force_default_llm,
  &__priority {
    display: flex;
    align-items: center;
  }
}

.rag-options {
  &__indexing-options {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.rag-uploader {
  width: 500px;

  &__search-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--primary-400);
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    padding: 0 0.5rem;

    &:focus,
    &:focus-within {
      @include default-focus();
    }

    &-container {
      display: flex;
      flex-grow: 1;
    }

    &__search-icon {
      background: none !important;
      color: var(--primary-medium);
    }

    &__input {
      width: 100% !important;
    }

    &__input,
    &__input:focus {
      margin: 0 !important;
      border: 0 !important;
      appearance: none !important;
      outline: none !important;
      background: none !important;
    }
  }

  &__uploads-list {
    margin-bottom: 20px;

    tbody {
      border-top: none;
    }
  }

  &__upload-status {
    text-align: right;
    padding-right: 0;

    .indexed {
      color: var(--success);
    }

    .uploaded,
    .indexing {
      color: var(--primary-low-mid);
    }
  }

  &__remove-file {
    text-align: right;
    padding-left: 0;
  }

  &__rag-file-icon {
    margin-right: 5px;
  }
}
.related-topics {
  margin: 4.5em 0 1em;
}

.more-topics__container {
  h3 .d-icon {
    margin-right: 0.25em;
    color: var(--primary-high);
    font-size: var(--font-down-1);
  }

  .nav-pills .d-icon {
    font-size: var(--font-down-1);
  }
}
.semantic-search__container {
  background: var(--primary-very-low);
  margin: 1rem 0 1rem 0;

  .semantic-search__results {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .ai-indicator-wave {
      color: var(--primary-medium);
    }

    .semantic-search {
      &__searching {
        display: flex;
        align-items: center;

        &.in-progress,
        &.unavailable {
          .semantic-search__searching-text {
            color: var(--primary-medium);
          }
        }
      }

      &__searching-text {
        display: inline-block;
        margin-left: 8px;
      }

      &__tooltip {
        margin-left: 4px;
        font-size: var(--font-down-1);
      }
    }

    .semantic-search__entries {
      margin-top: 10px;
    }

    .semantic-search__searching {
      margin-left: 5px;
    }
  }
}

.search-results {
  .fps-result {
    padding: 0.5rem;

    .ai-result__icon {
      display: none;
    }
  }

  .ai-result {
    border-radius: var(--d-border-radius);

    .ai-result__icon {
      display: inline;
      margin-right: 0.5rem;
      margin-left: auto;
      font-size: var(--font-up-2);
      color: var(--tertiary);
    }
  }
}

// Hides other buttons and only shows loader
// while AI quick search is in progress
.search-input {
  .ai-quick-search-spinner ~ a.clear-search,
  .ai-quick-search-spinner ~ a.show-advanced-search {
    display: none;
  }
}
.dashboard.dashboard-sentiment {
  .charts {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    .admin-report {
      grid-column: span 12;
    }
  }
}
.ai-llms-list-editor {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1em 0;
  }

  &__configured,
  &__templates {
    margin-top: 2em;
  }
}

.ai-llm-editor {
  padding-left: 0.5em;

  .ai-llm-editor-tests {
    &__failure {
      color: var(--danger);
    }

    &__success {
      color: var(--success);
    }
  }
}

[class*="ai-llms-list-editor"] {
  h3 {
    font-weight: normal;
    margin: 0;
    line-height: var(--line-height-medium);
  }
}

.ai-llms-list-editor__configured,
.ai-llms-list-editor__templates {
  h2 {
    font-size: var(--font-up-1);
  }
}

.ai-llms-list-editor__configured {
  .d-toggle-switch {
    justify-content: center;
  }
}

.ai-tool-list-editor__current,
.ai-persona-list-editor__current,
.ai-llms-list-editor__configured {
  .d-admin-table {
    tr:hover {
      background: inherit;
    }
    @include breakpoint("tablet", min-width) {
      th,
      td {
        &:first-child {
          padding-left: 0;
        }
      }
      th,
      td {
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.ai-llms-list-editor__templates {
  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
    gap: 1em 2em;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 3px solid var(--primary-low);
  }
  &-list-item {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 4;
    gap: 0;
    margin-bottom: 2em;
    @include breakpoint("mobile-extra-large", min-width) {
      margin-bottom: 3em;
    }
  }

  .admin-section-landing-item__description {
    color: var(--primary-high);
    margin: 0.25em 0 0.5em;
    line-height: var(--line-height-large);
    align-self: start;
    @include breakpoint("mobile-extra-large", min-width) {
      max-width: 17em;
    }
  }

  button {
    justify-self: start;
  }

  h4 {
    font-size: var(--font-down-1);
    font-weight: normal;
    color: var(--primary-high);
    margin: 0;
    letter-spacing: 0.1px;
  }
}

.ai-llm-list-editor__usages {
  list-style: none;
  margin: 0.5em 0 0 0;
  display: flex;
  li {
    font-size: var(--font-down-2);
    border-radius: 0.25em;
    background: var(--primary-very-low);
    border: 1px solid var(--primary-low);
    padding: 1px 3px;
    margin-right: 0.5em;
  }
}

.ai-llm-list__seeded-model {
  color: var(--primary-high);
  font-size: var(--font-down-1);
}

@include breakpoint("tablet") {
  .ai-llm-list__description {
    max-width: 80%;
  }
}
.ai-embedding-editor {
  padding-left: 0.5em;

  .ai-embedding-editor-input {
    width: 350px;
  }

  .ai-embedding-editor-tests {
    &__failure {
      color: var(--danger);
    }

    &__success {
      color: var(--success);
    }
  }

  &__api-key {
    margin-right: 0.5em;
  }

  &__secret-api-key-group {
    display: flex;
    align-items: center;
  }

  &__matryoshka_dimensions {
    display: flex;
    align-items: flex-start;
  }

  &__distance_functions.select-kit {
    .selected-name {
      .d-icon {
        width: 2em;
        height: 2em;
        position: absolute;

        + .name {
          margin-left: 2.25em;
        }
      }
    }

    .svg-icon-title {
      width: 2em;
      top: -0.5em;

      svg {
        width: 2em;
        height: 2em;
      }
    }
  }
}

.discourse-ai-embeddings {
  .btn-flat.back-button {
    padding-left: 0;
  }

  .fk-d-tooltip__icon {
    margin-left: 0.25em;
    color: var(--primary-medium);
  }

  textarea + .fk-d-tooltip__trigger {
    vertical-align: top;
  }

  .d-icon-circle-exclamation {
    color: var(--danger);
  }
}
.ai-usage {
  --chart-response-color: rgba(75, 192, 192, 0.8);
  --chart-request-color: rgba(153, 102, 255, 0.8);
  --chart-cached-color: rgba(153, 102, 255, 0.4);

  padding: 1em;

  &__filters-dates {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 1em;
  }

  &__period-buttons {
    display: flex;
    gap: 0.5em;
    align-items: center;

    .btn {
      padding: 0.5em 1em;

      &.btn-primary {
        background: var(--tertiary);
        color: var(--secondary);
      }
    }
  }

  &__custom-date-pickers {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-top: 0.5em;
  }

  &__filters {
    margin-bottom: 2em;
  }

  &__filters-period {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .d-date-time-input-range {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .d-date-time-input-range .from {
    margin: 0;
  }

  &__period-label {
    font-weight: bold;
  }

  &__summary {
    margin: 2em 0;
  }

  &__summary-title {
    margin-bottom: 1em;
    color: var(--primary);
    font-size: 1.2em;
  }

  &__charts {
    margin-top: 2em;
  }

  &__chart {
    position: relative;
  }

  &__chart-container {
    margin-bottom: 2em;
  }

  &__chart-title {
    margin-bottom: 1em;
  }

  &__breakdowns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    margin-top: 2em;

    @media (max-width: 768px) {
      grid-template-columns: none;
      display: flex;
      flex-direction: column;
    }
  }

  &__users {
    grid-column: span 2;

    .admin-config-area-card__content {
      display: flex;

      .ai-usage__users-table {
        &:first-child {
          margin-right: 2em;

          &.-double-width {
            margin-right: 0;
          }
        }

        &.-double-width {
          .ai-usage__users-username {
            width: auto;
          }
        }

        .ai-usage__users-username {
          width: 50px;
        }
      }
    }
  }

  &__features-table,
  &__users-table,
  &__models-table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      padding: 0.5em;
      border-bottom: 2px solid var(--primary-low);
    }
  }

  &__features-row,
  &__users-row,
  &__models-row {
    &:hover {
      background: var(--primary-low);
    }
  }

  &__features-cell,
  &__users-cell,
  &__models-cell {
    padding: 0.5em;
    border-bottom: 1px solid var(--primary-low);
  }
}
.ai-spam {
  --chart-scanned-color: var(--success);
  --chart-spam-color: var(--danger);
  padding-top: 15px;

  &__settings {
    margin-bottom: 2em;
  }

  &__enabled {
    display: flex;
    align-items: center;
    gap: 0.4em;
    margin-bottom: 1em;

    .fk-d-tooltip__trigger {
      color: var(--primary-high);
    }
  }

  &__settings-title {
    margin-bottom: 1em;
  }

  &__toggle,
  &__llm,
  &__instructions {
    margin-bottom: 1em;
  }

  &__toggle-label,
  &__llm-label,
  &__instructions-label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  &__instructions-input {
    width: 100%;
    min-height: 100px;
    margin-bottom: 0.5em;
  }

  &__stats {
    margin-top: 2em;
  }

  &__errors {
    .alert {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .btn {
        margin-left: auto;
      }
    }
  }
}

.spam-test-modal {
  &__body {
    min-width: 500px;
  }

  &__test-result {
    margin-top: 1.5em;
    padding-top: 1.5em;
    border-top: 1px solid var(--primary-low);
  }

  &__verdict {
    font-size: var(--font-up-2);
    font-weight: bold;
    padding: 0.5em;
    border-radius: 0.25em;
    text-align: center;
    margin: 1em 0;

    &.is-spam {
      background: var(--danger-low);
      color: var(--danger);
    }

    &.not-spam {
      background: var(--success-low);
      color: var(--success);
    }
  }

  &__log {
    margin-top: 1em;

    pre {
      max-height: 300px;
      overflow-y: auto;
      background: var(--primary-very-low);
      padding: 1em;
      margin: 0.5em 0;
      font-family: monospace;
      white-space: pre-wrap;
    }
  }
}
.ai-llm-quotas {
  margin: 1em 0;

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
  }

  &__table-head {
    background-color: var(--primary-very-low);
  }

  .duration-selector {
    .select-kit {
      width: 150px;
    }
  }

  .duration-selector__custom {
    margin-top: 8px;
  }

  &__header {
    text-align: left;
    padding: 0.5em;
    font-weight: bold;
    border-bottom: 2px solid var(--primary-low);

    &--actions {
      width: 50px;
    }
  }

  &__row {
    border-bottom: 1px solid var(--primary-low);
  }

  &__cell {
    vertical-align: middle;
    align-items: center;

    &--actions {
      text-align: center;
    }
  }

  &__input[type="number"] {
    width: 200px;
    padding: 0.5em;
    margin-bottom: 0;
  }

  &__group-select {
    width: 200px;
  }

  &__delete-btn {
    padding: 0.3em 0.5em;
  }

  &__add-btn {
    padding: 0.3em 0.5em;
  }
}

.ai-llm-quota-modal {
  .fk-d-tooltip__icon {
    color: var(--primary-medium);
  }
}
.ai-tool-parameter {
  margin-bottom: 2em;
  padding: 1.5em;
  border: 1px solid var(--primary-low);
  border-radius: 3px;
  background-color: var(--secondary-very-low);

  .parameter-row {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    input[type="text"] {
      flex-grow: 1;
      margin-right: 1em;
    }

    label {
      margin-right: 1em;
      white-space: nowrap;
    }
  }

  .parameter-enum-values {
    margin-top: 0.5em;

    .enum-value-row {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;

      input[type="text"] {
        flex-grow: 1;
        margin-right: 0.5em;
      }
    }
  }
}

.ai-tool-editor {
  max-width: 80%;
  position: relative;
  .ace-wrapper {
    border: 1px solid var(--primary-low);
    max-width: 100%;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 500px;
    .ace_editor {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.ai-tool-test-modal {
  &__test-result div {
    ul {
      padding-left: 1em;
    }
  }
}

.ai-tool-list-editor {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1em 0;

    h3 {
      margin: 0;
    }
  }
}
.ai-artifact__wrapper {
  iframe {
    width: 100%;
    height: calc(100% - 2em);
  }
  height: 500px;
  padding-bottom: 2em;
}

.ai-artifact__click-to-run {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ai-artifact__panel {
  display: none;
}

html.ai-artifact-expanded {
  overflow: hidden;
}

.ai-artifact__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ai-artifact__expand-button {
    margin-left: auto;
  }
}

.ai-artifact__expanded {
  .ai-artifact__footer {
    display: none;
  }

  .ai-artifact__panel--wrapper {
    position: fixed;
    top: 0;
    left: 2em;
    right: 2em;
    height: 2em;
    z-index: 1000000;
    animation: vanishing 0.5s 3s forwards;
  }

  .ai-artifact__panel {
    display: block;
    position: fixed;
    top: 0;
    left: 2em;
    right: 2em;
    height: 2em;
    transition: transform 0.5s ease-in-out;
    animation: slideUp 0.5s 3s forwards;
    background-color: var(--secondary-low);
    opacity: 0.9;
    transform: translateY(0);
    button {
      width: 100%;
      text-align: left;
      box-sizing: border-box;
      justify-content: flex-start;
      color: var(--secondary-very-high);
      &:hover {
        color: var(--secondary-very-high);
        .d-icon {
          color: var(--secondary-high);
        }
      }
    }
  }
  @keyframes slideUp {
    to {
      transform: translateY(-100%);
    }
  }

  @keyframes vanishing {
    to {
      display: none;
    }
  }

  iframe {
    position: fixed;
    top: 0;
    height: 100%;
    max-height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z("fullscreen");
  }

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: z("fullscreen");
  background-color: var(--secondary);
}
